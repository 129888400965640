export default class Brush {
  ctx;
  mouseX;
  mouseY;

  constructor(ctx, mouseX, mouseY) {
    this.ctx = ctx;
    this.mouseX = mouseX;
    this.mouseY = mouseY;
  }

  updateMousePosition(x, y) {
    this.mouseX = x;
    this.mouseY = y;
  }

  circle(r) {
    this.ctx.beginPath();
    this.ctx.arc(this.mouseX + r, this.mouseY + r, r, 0, Math.PI * 2, false);
    this.ctx.fillStyle = '#000000';
    this.ctx.fill();
    this.ctx.closePath();
  }

  /**
   * For spray get point position in the area to clear the canvas
   * @param {number} r
   * @returns {number[]}
   */
  clearPoint(r) {
    let radius = r;
    let x = Math.random() * 2 * radius - radius;
    let ylim = Math.sqrt(radius * radius - x * x);
    let y = Math.random() * 2 * ylim - ylim;
    x += radius;
    y += radius;

    x += this.mouseX;
    y += this.mouseY;

    return [x, y];
  }

  /**
   * Create a set of points allocated in area,
   * @param {number} area
   * @param {number} dropsSize
   * @param {number} dropsCount
   */
  spray(area, dropsSize, dropsCount) {
    let i = 0;

    for (i; i < dropsCount; i++) {
      let points = this.clearPoint(area / 2);
      this.ctx.beginPath();
      this.ctx.arc(points[0] + (area / 2), points[1] + (area / 2), dropsSize / 2, 0, Math.PI * 2, false);
      this.ctx.fillStyle = '#000000';
      this.ctx.fill();
      this.ctx.closePath();
    }
  }

  /**
   * Draw the brush image on canvas
   * @param {HTMLImageElement} img
   */
  brush(img) {
    if (!img) {
      let error = new Error('argument img is not a node IMG');
      console.log(error.message);
      return;
    }
    let angle = Math.atan2(this.mouseY, this.mouseX);
    this.ctx.save();
    this.ctx.translate(this.mouseX, this.mouseY);
    this.ctx.rotate(angle);
    this.ctx.drawImage(img, -(img.width / 2), -(img.height / 2));
  }

  startLine(r) {
    this.ctx.beginPath();
    this.ctx.strokeStyle = 'black';
    this.ctx.lineWidth = r;
    this.ctx.lineJoin = this.ctx.lineCap = 'round';
    this.ctx.moveTo(this.mouseX + r, this.mouseY + r);
  }

  drawLine(r) {
    this.ctx.filter = 'blur(1px)';
    this.ctx.lineTo(this.mouseX + r, this.mouseY + r);
    this.ctx.stroke();
  }
}
