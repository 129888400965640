<template>
  <div
    ref="mainContainer"
    :class="`${campaignData.theme_type === 'without_card' ? 'without_card container-class' : 'container-class'}`"
    :style="{ backgroundImage: `url(${campaignData.bg_image})` }"
  >
    <div class="d-flex flex-column sub-container">
      <!-- <power-by
        v-if="
          campaignData.powered_by_text &&
          campaignData.theme_type === 'verticle'
        "
        :campaignData="campaignData"
      /> -->
      <ScratchCardComponent
        v-bind:style="step2CardStyle"
        :campaignData="campaignData"
        :scratchPercentage="Number(campaignData.scratch_percentage)"
        @onScratchComplete="showNextModal()"
      />
      <power-by
        v-if="
          campaignData.powered_by_text
          // && campaignData.theme_type !== 'verticle'
        "
        :campaignData="campaignData"
      />
    </div>
  </div>
</template>

<script>
import PowerBy from "@/components/powerby/PowerBy.vue";
import ScratchCardComponent from "@/components/scratch-card/ScratchCardComponent.vue";

export default {
  name: "Page2",
  components: {
    PowerBy,
    ScratchCardComponent,
  },
  computed: {
    isWinner: function () {
      return this.$store.getters.isWinner;
    },
    campaignData: function () {
      return this.$store.getters.campaignData;
    },
    isSubscribed: function () {
      return this.$store.getters.isSubscribed;
    },
    isResultPageVisited: function () {
      return this.$store.getters.isResultPageVisited;
    },
    step2CardStyle: function () {
      return this.$store.getters.step2CardStyle;
    },
  },
  methods: {
    showNextModal() {
      this.$store.commit("setPageCount", 3);
    },
    checkStatus() {
      if (!this.isSubscribed || this.isResultPageVisited) {
        this.$router.replace({ path: `/${this.$route.params.slug}` });
        this.$store.commit("setPageCount", 1);
        this.clearData();
      }

      if (!this.isResultPageVisited) {
        this.$store.commit("managePageVisit", true);
      }
    },
    clearData() {
      this.$store.commit("setCampaignData", {});
      this.$store.commit("setWinnerFlag", false);
      this.$store.commit("manageSubscribeFlag", false);
    },
  },
  created() {
    this.checkStatus();
  },
};
</script>
