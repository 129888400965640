<template>
  <div
    v-if="campaignData.theme_type === 'verticle'"
    class="card-view pd-5 d-flex flex-direction-column d-xs-block"
  >
    <div class="scard-container2 text-center">
      <h2 v-bind:style="step2HeaderStyle" class="title-class">
        {{ campaignData.step2_title }}
      </h2>
      <p v-bind:style="step2TextStyle" class="msg-txt px-3">
        {{ campaignData.step2_description }}
      </p>
    </div>
    <div
      ref="scratchContainer"
      id="scratch-container"
      class="scratch_container mt-20"
    />
  </div>
  
  <div
    v-else-if="campaignData.theme_type === 'without_card'"
    class="card-view pd-5 d-flex flex-direction-column d-xs-block"
  >
    <img
      :src="campaignData.campaign_image"
      style=""
      class="theme3_top_img"
    />
    <div class="scard-container2 text-center">
      <h2 v-bind:style="step2HeaderStyle" class="title-class">
        {{ campaignData.step2_title }}
      </h2>
      <p v-bind:style="step2TextStyle" class="msg-txt px-3">
        {{ campaignData.step2_description }}
      </p>
    </div>
    <div
      ref="scratchContainer"
      id="scratch-container"
      class="scratch_container mt-20"
    />
  </div>
  <div v-else class="card-view pd-5 d-flex d-xs-block">
    <div
      ref="scratchContainer"
      id="scratch-container"
      class="scratch_container"
    />
    <div class="scard-container2 text-center">
      <h2 v-bind:style="step2HeaderStyle" class="title-class">
        {{ campaignData.step2_title }}
      </h2>
      <p v-bind:style="step2TextStyle" class="msg-txt px-3">
        {{ campaignData.step2_description }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
import {
  ScratchCard,
  SCRATCH_TYPE,
} from "../../assets/js/scratchcard/ScratchCard";
export default {
  name: "ScratchCardComponent",
  props: {
    campaignData: Object,
    scratchPercentage: {
      type: Number,
      default: 90,
      required: false,
    },
  },
  computed: {
    isWinner: function () {
      return this.$store.getters.isWinner;
    },
    priceId: function () {
      return this.$store.getters.priceId;
    },
    canvas: function () {
      return this.$refs.scratchCardCanvas;
    },
    ctx: function () {
      return this.canvas.getContext("2d");
    },
    canvasWidth: function () {
      return this.canvas.width;
    },
    canvasHeight: function () {
      return this.canvas.height;
    },
    backView: function () {
      return this.$refs.backView;
    },
    step2HeaderStyle: function () {
      return this.$store.getters.step2HeaderStyle;
    },
    step2TextStyle: function () {
      return this.$store.getters.step2TextStyle;
    },
    scratchContainer: function () {
      return this.$refs.scratchContainer;
    },
  },
  data() {
    return {
      backImageUrl: "",
      resultData : {},
    };
  },
  methods: {
    ...mapActions(["getResult"]),
    scratchComplete() {
      this.$emit("onScratchComplete");
    },
    async setResult(Result) {
      await this.getResult(Result);
    },
  },
  created() {
    if (this.isWinner) {
      
      this.campaignData.scratch_prices.forEach(e => {
        this.resultData[e.id] = e;
      });

      this.setResult(this.resultData[this.priceId] ? this.resultData[this.priceId] : []);

      // if (
      //   this.campaignData.prize_data &&
      //   this.campaignData.prize_data.image != ""
      // ) {
      //   this.backImageUrl = this.campaignData.prize_data.image;
      // } else {
      //   this.backImageUrl = this.campaignData.card_back;
      // }
      this.backImageUrl = this.campaignData.card_winning;
    } else {
      this.backImageUrl = this.campaignData.card_back;
    }
  },
  mounted() {
    const th = this;
    setTimeout(() => {
      var sc = new ScratchCard("#scratch-container", {
        enabledPercentUpdate: true,
        scratchType: SCRATCH_TYPE.CIRCLE,
        brushSrc: require("../../assets/img/brush.png"),
        containerWidth: this.scratchContainer.offsetWidth,
        containerHeight: 300,
        imageForwardSrc: this.campaignData.card_step2,
        imageBackgroundSrc: this.backImageUrl,
        htmlBackground: "",
        clearZoneRadius: 30,
        percentToFinish: this.scratchPercentage, // When the percent exceeds 50 on touchend event the callback will be exec.
        nPoints: 0,
        pointSize: 0,
        cursorImage: this.campaignData?.scratch_cursor_image || "",
        callback: function () {
          th.scratchComplete();
        },
      });
      sc.init();
    }, 500);
  },
};
</script>
